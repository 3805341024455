import React, { useState } from "react";
import ContactForm from "./ContactForm";
// import FadeInWhenVisible from "./smaller/FadeInWhenVisible";
import emailjs from "@emailjs/browser";
import { emailjsValues, mailNaam } from "../../data/globals";
import toast from "react-hot-toast";
import NavBar from "../Navbar/NavBar";
import Footer from "../Footer";

const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    setIsSubmitting(true);
    const data = {
      to_name: mailNaam.value,
      from_name: formData.get("firstName") + " " + formData.get("lastName"),
      from_email: formData.get("email"),
      message: formData.get("message"),
    };

    emailjs
      .send(
        emailjsValues.service,
        emailjsValues.template,
        data,
        emailjsValues.user_id
      )
      .then(
        () => {
          toast.success("Uw bericht is succesvol verstuurd!");
          event.target.reset(); // Reset the form after successful submission
          setIsSubmitting(false);
        },
        (error) => {
          toast.error(error.text);
          setIsSubmitting(false);
        }
      );

    console.log(data);
  };
  return (
    <>
      <div>
        <NavBar />
      </div>
      <ContactForm
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <Footer />
    </>
  );
};

export default Contact;
