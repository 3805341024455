export const telnr = {
    value: "0612345678",
    label: "0612345678",
}

export const email = {
    value: "info@dh-toegangstechniek.nl",
}

export const linkToLinkedin = {
    value: "https://www.linkedin.com/in/arjan-den-heijer-b80461215/",
}

export const mailNaam = {
    value: "Arjan",
}

export const emailjsValues ={
    service: 'service_s03rfrl',
    template: 'template_3vfnrro',
    user_id: '6WeezLc11-iZyhk0S'
}

export const overMijTekst = {
    value: "Den Heijer toeganstechniek biedt professioneel, merkonafhankelijk onderhoud voor automatische deuren. Door regelmatig onderhoud aan uw automatische schuifdeur, draaideurautomaat of toegangspoort te laten uitvoeren, kunt u talloze storingen voorkomen en de levensduur van uw apparatuur verlengen. \n Wij zijn gespecialiseerd in het onderhouden, repareren en bieden van service voor alle vooraanstaande merken, waaronder Record, Geze, Dorma, Gunnebo, Label, AssaAbloy en Kone. \n\nBij ToegangsTechniek Den Heijer staan wij klaar om u te helpen bij reparaties, het verhelpen van storingen en andere gebreken. U kunt contact met ons opnemen via het contactformulier op onze website of rechtstreeks via ons e-mailadres: ",
}