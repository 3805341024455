import React from "react";
import { HashLink } from "react-router-hash-link";

const NavLinks = () => {
  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#over_ons"
      >
        Over ons
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#diensten"
      >
        Diensten
      </HashLink>
      {/* <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/"
      >
        Portfolio
      </HashLink> */}
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/contact#contact"
      >
        Contact
      </HashLink>
      <HashLink
        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth
        to="/contact#contact"
        // to="/get-demo#demo"
      >
        Vraag offerte aan
      </HashLink>
    </>
  );
};

export default NavLinks;
