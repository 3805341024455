import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { telnr } from "../data/globals";
import firstWave from "../images/bg/firstwave.svg";
import secondWave from "../images/bg/secondwave.svg";
import waveFull from "../images/bg/waveFull.svg";
import { AiFillLinkedin, AiOutlineLinkedin } from "react-icons/ai";
import { linkToLinkedin } from "../data/globals";

const Footer = () => {
  return (
    <>
      <footer className="relative overflow-hidden">
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 1st block */}
            <div className="hidden lg:block col-span-12 lg:col-span-4">
              <div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80">
                {/* <img src={logo} alt="logo" /> */}
                <h3 className="font-bold md:text-4xl mb-4 text-[#013289]">
                  DH{" "}
                  <span className="text-yellow-500 text-2xl">
                    Toegangstechniek
                  </span>
                </h3>
                <div className="text-md font-medium text-gray-600">
                  <h5>Arjan den Heijer</h5>
                  <p>Zoetermeer,</p>
                  <p>NL</p>
                  <p>1234AB</p>
                  <a
                    href={`tel:${telnr.label}`}
                    className="text-[#013289] font-bold"
                  >
                    {telnr.label}
                  </a>
                </div>
              </div>
            </div>

            {/* 2nd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md ">
                <li className="mb-2 hover:scale-x-110 transition duration-250 ease-in-out">
                  <HashLink
                    to="#over_ons"
                    className="text-[#013289] hover:text-gray-900"
                  >
                    Over ons
                  </HashLink>
                </li>
                <li className="mb-2 hover:scale-x-110 transition duration-250 ease-in-out">
                  <HashLink
                    to="#diensten"
                    className="text-[#013289] hover:text-gray-900"
                  >
                    Diensten
                  </HashLink>
                </li>
                <li className="mb-2 hover:scale-x-105 transition duration-250 ease-in-out">
                  <HashLink
                    to="contact#contact"
                    className="text-[#013289] hover:text-gray-900"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
              <h6 className="text-[#013289] text-xl font-bold mb-4">
                Onze diensten
              </h6>
              <ul className="text-md hover:scale-110 transition duration-250 ease-in-out">
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-neutral-700"
                  >
                    Elektrische deuren
                  </Link>
                </li>
                {/* <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Mobile App Development
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Domain and Hosting
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="#"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    General IT Consultations
                  </Link>
                </li> */}
              </ul>
            </div>

            {/* 4th block */}
            <div className="hidden lg:block col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
              <div className="text-xl mb-6">Social Media.</div>

              <div className="text-md font-medium mb-6">
                Volg ons op Social media:
              </div>
              <div className="mx-auto text-center mt-2">
                <ul className="flex justify-center mb-4 md:mb-0">
                  <li>
                    <a
                      href={`${linkToLinkedin.value}`}
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-full bg-white h-8 w-8 text-center flex justify-center items-center hover:scale-110 "
                    >
                      <AiFillLinkedin color="blue" />
                    </a>
                  </li>
                  <li className="ml-4">
                    <Link
                      to="#"
                      className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                      aria-label="Facebook"
                    >
                      <svg
                        className="w-8 h-8 fill-current"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                      </svg>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashLink to="#" className=" hover:text-gray-900">
                  Daniël den Heijer
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
        {/* Background SVGs container */}
        <div className="absolute inset-0 z-[-1] pointer-events-none">
          {/* First SVG */}
          <img
            src={waveFull}
            alt="wave"
            className="absolute w-screen top-0 left-0"
            style={{ transform: "scaleY(2)" }}
          />
          {/* Second SVG{" "}
          <img
            src={secondWave}
            alt="wave"
            className="absolute w-screen top-0 left-0 opacity-50"
          />
          {/* First SVG */}
          {/* <img
            src={firstWave}
            alt="wave"
            className="absolute w-screen top-0 left-0 opacity-50"
          /> */}
        </div>
      </footer>
    </>
  );
};
export default Footer;
