import React from "react";
import safetyImg from "./icons/safety.svg";
import imgSolving from "./icons/compass.svg";
import img4 from "./icons/repairWrench.svg";
import imgDoorKey from "./icons/key.svg";
import handshake from "./icons/handshake.svg";
import toolsPen from "./icons/toolsPen.svg";

const Diensten = () => {
  return (
    <div id="diensten" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-extrabold">
            Diensten
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-yellow-500">
            Wij zetten ons volledig in voor het succes en de groei van onze
            klanten.
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.10] transition duration-1000 ease-in-out mx-auto"
                  src={imgDoorKey}
                  style={{ height: "300px", width: "300px" }}
                />
                {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                /> */}
                {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img}
                /> */}
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Uw Toegang, Onze Expertise
                </h2>
                <p className="text-md font-medium">
                  Wij specialiseren ons in de installatie, reparatie en
                  onderhoud van alle soorten elektrische deuren en poortopeners.
                  Of het nu gaat om uw huis, bedrijf of industrieel pand, wij
                  zorgen voor een veilige en betrouwbare toegang.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.10] transition duration-1000 ease-in-out mx-auto"
                  src={imgSolving}
                  style={{ height: "300px", width: "300px" }}
                />
                {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                /> */}
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Slimme Oplossingen voor Elke Deur
                </h2>
                <p className="text-md font-medium">
                  Van garagedeuren en schuifpoorten tot industriële
                  toegangssystemen, wij bieden op maat gemaakte oplossingen die
                  perfect aansluiten op uw behoeften. Profiteer van de nieuwste
                  technologieën voor meer comfort en veiligheid.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.10] transition duration-1000 ease-in-out mx-auto"
                  src={safetyImg}
                  style={{ height: "300px", width: "300px" }}
                />
                {/* <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                /> */}
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  Veiligheid en Gemak op Afstand Bedienen
                </h2>
                <p className="text-md font-medium">
                  Met onze slimme besturingssystemen kunt u uw deuren en poorten
                  eenvoudig bedienen met uw smartphone of afstandsbediening. Wij
                  zorgen voor een naadloze integratie, zodat u altijd de
                  controle heeft, waar u ook bent.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.10] transition duration-1000 ease-in-out mx-auto"
                  src={img4}
                  style={{ height: "300px", width: "300px" }}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  24/7 Service, Snelle Reparatie
                </h2>
                <p className="text-md font-medium">
                  Een defecte deur kan voor grote ongemakken zorgen. Daarom
                  staan wij 24/7 voor u klaar met onze snelle reparatieservice.
                  Bel ons op elk moment en wij zorgen ervoor dat uw deur snel
                  weer werkt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center pt-12">
              <div className="text-blue-900 mb-4">
                <img
                  src={toolsPen}
                  alt="card img"
                  className="rounded-t transition duration-1000 ease-in-out"
                  style={{ height: "90px" }}
                />
              </div>
              <h3
                className="text-3xl  text-blue-900 
                            font-bold"
              >
                Wij <span className="font-black text-yellow-500">Bouwen</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  Met meer dan 10 jaar ervaring in de montage van elektrische
                  deuren en een grondige kennis van de nieuwste technologieën en
                  oplossingen, bieden wij op maat gemaakte adviezen en
                  strategieën om uw installaties te optimaliseren, kosten te
                  verlagen en de efficiëntie te verhogen.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <img
                  src={handshake}
                  alt="card img"
                  className="rounded-t transition duration-1000 ease-in-out"
                  style={{ height: "100px" }}
                />
              </div>
              <h3 className="text-3xl  text-blue-900 font-bold">
                We werken{" "}
                <span className="font-black text-yellow-500">samen</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We werken samen met uw bestaande team om elektrische deuren
                  efficiënt te installeren en te integreren, afgestemd op uw
                  specifieke wensen. Dit zorgt voor een naadloze werking en
                  vereenvoudigt diverse processen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Diensten;
