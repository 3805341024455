import React from "react";
import Clients from "../components/Clients";
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
// import Portfolio from '../components/Portfolio';
import Diensten from "../components/Diensten";

const Home = () => {
  return (
    <>
      <Hero />
      <Intro />
      <Diensten />
      {/* <Portfolio /> */}
      <Clients />
      <Cta />
      <Footer />
    </>
  );
};

export default Home;
