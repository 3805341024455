import React, { useState } from "react";
import { AiFillLinkedin, AiFillMail, AiFillPhone } from "react-icons/ai";
import { email, linkToLinkedin, telnr } from "../../data/globals";
// import FadeInWhenVisible from "./FadeInWhenVisible";

const ContactForm = ({ handleSubmit, isSubmitting }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) newErrors.firstName = "Voornaam is vereist";

    if (!formData.email.trim()) newErrors.email = "Email is vereist";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Ongeldig email adres";

    if (!formData.message.trim()) newErrors.message = "Bericht is vereist";
    else if (formData.message.length < 20)
      newErrors.message = "Bericht moet ten minste 20 tekens bevatten";
    else if (formData.message.length > 2000)
      newErrors.message = "Bericht mag niet meer dan 2000 tekens bevatten";

    setErrors(newErrors);
    console.log(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear the error when the input is changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the specific error for this field
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
    }
  };
  return (
    <div
      id="contact"
      className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 "
    >
      <div
        className="w-full flex lg:flex-row flex-col mx-auto my-8 px-6 md:px-24 lg:px-20 justify-center items-center"
        data-aos="zoom-in"
      >
        <form onSubmit={onSubmit}>
          {" "}
          {/* Add onSubmit event handler */}
          <div className="w-full bg-white p-2 md:p-8 md:pb-8 lg:p-8 lg:pt-0 my-4 md:px-12 lg:px-20 mr-auto rounded-2xl shadow-[0px_0px_10px_5px_#718096]">
            <div className="flex">
              <h1 className="font-extrabold uppercase text-2xl md:text-5xl p-6 lg:py-10 text-blue-900">
                Neem contact op
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
              <div>
                <label
                  htmlFor="firstName"
                  className="block pl-2 text-start text-gray-700"
                >
                  Voornaam *
                </label>
                {/* border border-gray-300 shadow-sm rounded-md py-2 px-4 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 */}
                <input
                  name="firstName"
                  id="firstName"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 focus:shadow-outline
                                                        border border-gray-300 shadow-sm rounded-xl focus:outline-none focus:border-blue-500 focus:ring focus:rind-blue-200"
                  type="text"
                  placeholder="Voornaam"
                  onChange={handleChange}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs italic text-start p-2 font-bold">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="lastName"
                  className="block pl-2 text-start text-gray-700"
                >
                  Achternaam
                </label>
                <input
                  name="lastName"
                  id="lastName"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 focus:shadow-outline
                                                    border border-gray-300 shadow-sm rounded-xl focus:outline-none focus:border-blue-500 focus:ring focus:rind-blue-200"
                  type="text"
                  placeholder="Achternaam"
                  onChange={handleChange}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs italic text-start p-2 font-bold">
                    {errors.lastName}
                  </p>
                )}
              </div>
              <div className="md:col-span-2">
                <label
                  htmlFor="email"
                  className="block pl-2 text-start text-gray-700"
                >
                  Email *
                </label>
                <input
                  id="email"
                  name="email"
                  className="w-full bg-gray-100 text-gray-900 mt-2 p-3 focus:shadow-outline
                                                    border border-gray-300 shadow-sm rounded-xl focus:outline-none focus:border-blue-500 focus:ring focus:rind-blue-200"
                  // type="email"
                  placeholder="Email"
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs italic text-start p-2 font-bold">
                    {errors.email}
                  </p>
                )}
              </div>
            </div>
            <div className="my-4">
              <label
                htmlFor="message"
                className="block pl-2 text-start text-gray-700"
              >
                Bericht *
              </label>
              <textarea
                name="message"
                id="message"
                placeholder="Bericht"
                className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 focus:shadow-outline
                    border border-gray-300 shadow-sm rounded-xl focus:outline-none focus:border-blue-500 focus:ring focus:rind-blue-200"
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <p className="text-red-500 text-xs italic text-start p-2 font-bold">
                  {errors.message}
                </p>
              )}
            </div>
            <div className="my-2">
              {isSubmitting ? (
                <p>Uw bericht wordt verstuurd...</p>
              ) : (
                <button
                  name="submitButton"
                  type="submit"
                  className="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                >
                  Verstuur Bericht
                </button>
              )}
            </div>
          </div>
        </form>

        {/* <div className="flex flex-col w-1/2 md:w-2/3 justify-center lg:-mt-[180px] lg:-mr-[160px] lg:w-2/6 px-2 py-4 lg:ml-auto bg-blue-900 rounded-2xl lg:shadow-[10px_10px_20px_2px_var(--text)]"> */}
        {/* <FadeInWhenVisible duration={0.6}> */}
        <div className="w-full lg:mt-96 mb-20 lg:-ml-2 md:w-min px-16 py-6 bg-blue-900 rounded-2xl text-white">
          {/* <div className="p-2 m-5 md:w-4/12 rounded-2xl h-1/2 flex flex-col text-white items-center my-auto justify-center bg-blue-900 "> */}
          <div className="flex my-4">
            <div className="flex flex-col">
              <h2 className="text-2xl pb-2">Bel of mail ons:</h2>
              <a href={`tel:${telnr.label}`}>
                <p className="text-gray-400 text-lg">Tel: {telnr.label}</p>
              </a>
              <a href={`mailto:${email.value}`}>
                <p className="text-gray-400 text-lg">Email: {email.value}</p>
              </a>
            </div>
          </div>

          <div className="flex flex-row justify-evenly gap-2 md:justify-start my-4 w-full">
            <a
              href={`mailto:${email.value}`}
              className="rounded-full bg-white h-8 w-8 flex justify-center items-center hover:scale-110 "
            >
              <AiFillMail color="var(--text)" />
            </a>
            <a
              href={`tel:${telnr.label}`}
              rel="noreferrer"
              className="rounded-full bg-white h-8 w-8 flex justify-center items-center hover:scale-110 "
            >
              <AiFillPhone color="var(--text)" />
            </a>
            <a
              href={`${linkToLinkedin.value}`}
              target="_blank"
              rel="noreferrer"
              className="rounded-full bg-white h-8 w-8 text-center flex justify-center items-center hover:scale-110 "
            >
              <AiFillLinkedin color="var(--text)" />
            </a>
          </div>
        </div>
        {/* </FadeInWhenVisible> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default ContactForm;
